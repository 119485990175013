import React from "react";
import "./_DownloadButton.css";

const DownloadButton = ({ name, link }) => {
  function handleDownloadBttn() {
    window.open(link, "_blank");
  }
  return (
    <button className="downloadButton" onClick={handleDownloadBttn}>
      {name}
    </button>
  );
};

export default DownloadButton;
